$(document).on('turbolinks:load', () => {
  $('#js-registration-save-btn').click(function(event) {
    const expertInfoValue = $('#js-expert-info-input').val();
    const serialNumberValue = $('#js-serial-number-input').val();

    if (expertInfoValue.match(/^\d{9}$/)) {
      const helper = buttonHelper(event.currentTarget);
      helper.showLoader();
      event.preventDefault();
      $.get('/search/registered_telephone_numbers/' + expertInfoValue, function(data) {
        if (data.registered_telephone_number) {
          $('#new_registration').submit();
        } else {
          $('#js-non-existing-phone-modal').show();
        }
        helper.enableButton();
      });
    } else if (expertInfoValue === '' || serialNumberValue === '') {
      event.preventDefault();
      $('#js-blank-field-confirmation-modal').show();
    }
  });

  $('#js-blank-field-modal-close-btn').click(function() {
    $('#js-blank-field-confirmation-modal').hide();
  });

  $('#js-blank-field-modal-submit-btn').click(function() {
    $('#js-blank-field-confirmation-modal').hide();
    $('#new_registration').submit();
  });

  $('#js-non-existing-iqos-card-modal-close-btn').click(function() {
    $('#js-non-existing-iqos-card-modal').hide();
  });

  if ($('#js-non-existing-iqos-card-modal').data('show-modal')) {
    $('#js-non-existing-iqos-card-modal').show();
  }

  $('#js-non-existing-phone-modal-close-btn').click(function() {
    $('#js-non-existing-phone-modal').hide();
  });

  $('#js-non-existing-phone-modal-submit-btn').click(function() {
    $('#js-non-existing-phone-modal').hide();
    $('#new_registration').submit();
  });

  $('.js-disabled-enter').keypress(function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  });

  $('#new_registration').on('submit', function() {
    const helper = buttonHelper($('#js-registration-save-btn')[0]);
    helper.showLoader();
  });

  const buttonHelper = function(button) {
    const oldHtml = button.innerHTML;

    return {
      showLoader: () => {
        button.innerHTML = '<span class="spinner-border spinner-border-sm"></span>';
        button.disabled = true;
      },
      enableButton: () => {
        button.innerHTML = oldHtml;
        button.disabled = false;
      }
    };
  };
});
